import xhr from "../config"

export const getData = async (id) => {
    try {
        const res = await xhr.post('/smart-biztrip/hit/response/traveloka', { id })

        if (!res.data.error) return res.data.data
        throw res.data.message
    } catch (error) {
        throw error
    }
}

export const submitSummary = async (payload) => {
    try {
        const res = await xhr.post('/25236/smart-biztrip/record/set', payload)

        if (!res.data.error) return res.data.data
        throw res.data.message
    } catch (error) {
        throw error
    }
}

export const getTicketsData = async (payload) => {
    try {
        const res = await xhr.post('/25236/biztrip/trip-travel-order/list', {
            "data": {
                  "id_trip_travel_order": payload
            }
        })

        console.log({RESP_getTicketData: res})
        if (!res.data.error && !res.data.isThreshold) return res.data
        throw res.data.message
    } catch (error) {
        throw error
    }
}

export const getHotelRooms = async (payload) => {
    try {
        const res = await xhr.post('/25236/biztrip/hotel/room/list', payload)

        if (!res.data.error) return res.data
        throw res.data.message
    } catch (error) {
        throw error
    }
}

export const sendSummary = async (payload) => {
    try {
        const res = await xhr.post('/25236/biztrip/trip-travel-order/summary-trip', payload)
        if (!res.data.error) return res.data
        throw res.data.message
    } catch (error) {
        throw error
    }
}