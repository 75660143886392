import React from 'react'
import { 
    Button, 
    Flex, 
    Image, 
    Text, 
} from '@chakra-ui/react'

function FlightItem({data, onClick, isDisable}) {
    const handleNumberToHoursAndMinutes = (number) => {
        const hours = Math.floor(number / 60)  
        const minutes = number % 60
        return hours + "h" + " " + minutes + "m"  
    } 

    return (
        <Flex 
            flexDirection={'column'} 
            boxShadow={'0px 0px 10px 1px #e8e8e8'}
            rounded={'md'} 
            padding={'20px'}
        >
            <Flex alignItems={'center'} gap={'10px'}>
                <Image
                    height={'40px'}
                    src={data?.airlineDetail[0]?.logoUrl}
                    objectFit={'cover'}
                />
                <Text fontWeight={'semibold'}>{data?.airlineDetail[0]?.airlineName} ({data?.journeys[0]?.segments[0]?.flightCode})</Text>
            </Flex>
            <Flex 
                gap={'20px'} 
                justifyContent={'space-between'} 
                marginTop={'20px'}
            >
                <Flex flexDirection={'column'}>
                    <Text fontWeight={'semibold'}>{data?.journeys[0]?.departureDetail?.departureTime}</Text>
                    <Text fontWeight={'thin'}>{data?.departureAirportDetail?.city} ({data?.departureAirport})</Text>
                </Flex>
                <Flex flexDirection={'column'}>
                    <Text fontWeight={'semibold'}>{data?.journeys[0]?.arrivalDetail?.arrivalTime}</Text>
                    <Text fontWeight={'thin'}>{data?.arrivalAirportDetail?.city} ({data?.arrivalAirport})</Text>
                </Flex>
                <Flex flexDirection={'column'}>
                    <Text fontWeight={'semibold'}>{handleNumberToHoursAndMinutes(data?.journeys[0].journeyDuration)}</Text>
                    <Text fontWeight={'thin'}>{data?.numOfTransits === '0' ? 'direct' : 'transit'}</Text>
                </Flex>
                <Flex flexDirection={'column'}>
                    <Text fontWeight={'semibold'} color={'orange.400'}>{data?.journeys[0]?.fareInfo?.netToAgent?.adultFare?.currency}. {(Number(data?.journeys[0]?.fareInfo?.netToAgent?.adultFare?.amount)).toLocaleString()}</Text>
                    <Text fontWeight={'thin'}>/pax</Text>
                    <Button 
                        backgroundColor={'orange.400'} 
                        color={'white'} 
                        onClick={onClick}
                        isDisabled={isDisable}
                    >
                        Choose
                    </Button>
                </Flex>
            </Flex>
            <Flex direction='column'>
                {data?.journeys[0]?.refundableStatus === 'REFUNDABLE' 
                    ? <Text color='green.300'>REFUNDABLE</Text>
                    : <Text color='red.300'>NOT REFUNDABLE</Text>
                }
                {data?.journeys[0]?.segments[0]?.addOns?.mealOptions?.length === 0 
                    ? <Text color='red.300'>Allowance Excluded</Text>
                    : <Text color='green.300'>Allowance Included</Text>
                }
            </Flex>
            <Flex gap={'20px'} marginTop='20px'>
                <Text cursor={'pointer'}>Flight Details</Text>
                <Text cursor={'pointer'}>Fare Info</Text>
            </Flex>
        </Flex>
    )
}

export default FlightItem