import React from 'react'
import { Box, Flex, Icon, Image, Text } from '@chakra-ui/react'
import { MdOutlineFlightTakeoff, MdOutlineFlightLand } from 'react-icons/md'
import { IoMdCloseCircle } from 'react-icons/io'

function SelectedFlight({ data, onDelete, type }) {
    return (
        <Flex 
            alignItems={'center'} 
            gap={'20px'} 
            padding={'20px'}
            rounded={'md'}
            boxShadow={'0px 0px 10px 1px #e8e8e8'}
            width={'max-content'}
            position={'relative'}
        >
            { type === 'departure' 
                ?   <MdOutlineFlightTakeoff fontSize={'50px'} />
                :   <MdOutlineFlightLand fontSize={'50px'} />
            }
            <Flex flexDirection={'column'}>
                <Flex 
                    alignItems={'center'} 
                    gap={'10px'} 
                    marginBottom={'10px'}
                >
                    <Image
                        height={'40px'}
                        src={data?.airlineDetail[0]?.logoUrl}
                        objectFit={'cover'}
                    />
                    <Text fontWeight={'semibold'}>{data?.airlineDetail[0]?.airlineName} ({data?.journeys[0]?.segments[0]?.flightCode})</Text>
                </Flex>
                <Text fontWeight={'medium'}>{data?.departureAirport}-{data?.arrivalAirport} | {data?.journeys[0]?.departureDetail?.departureTime}-{data?.journeys[0]?.arrivalDetail?.arrivalTime}</Text>
                <Text fontWeight={'medium'}>
                    {data?.journeys[0]?.fareInfo?.netToAgent?.adultFare?.currency}. {(Number(data?.journeys[0]?.fareInfo?.netToAgent?.adultFare?.amount)).toLocaleString()}
                    <Box as={'span'} fontWeight={'thin'}> / pax</Box>
                </Text>
            </Flex>
            <Icon 
                as={IoMdCloseCircle} 
                color={'red'} 
                fontSize={'24px'} 
                position={'absolute'}
                right={'-10px'}
                top={'-10px'}
                cursor={'pointer'}
                onClick={onDelete}
            />
        </Flex>
    )
}

export default SelectedFlight