import { extendTheme } from '@chakra-ui/react'

const theme = extendTheme({
    fonts: {
        heading: '"Poppins", sans-serif',
        body: '"Poppins", sans-serif',
    },
})

export default theme
