import React from 'react'
import { useSearchParams } from 'react-router-dom'
import { useMutation } from 'react-query'
import { sendSummary } from '../../../api/requests/oos'
import { format } from 'date-fns'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Flex,
    Text,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    Box,
} from '@chakra-ui/react'

function ModalSummary({ 
    selectedFlight,
    selectedDepartureFlight,
    selectedReturnFlight,
    selectedFlightBaggage,
    selectedDepartureFlightBaggage,
    selectedReturnFlightBaggage,
    selectedHotel,
    trip,
    tickets,
    isOpen,
    onClose,
    onSuccess,
}) {
    const [searchParams] = useSearchParams()

    function handleCalculateTotalPrice() {
        let roundTripPrice = 0
        let notRountripPrice = 0
        let hotelPrice = 0

        if (tickets?.isFlightNotRoundTrip) {
            notRountripPrice = 
                (Number(selectedFlight?.journeys[0]?.fareInfo?.netToAgent?.adultFare?.amount) * trip?.passenger?.length) +
                (Number(selectedFlightBaggage?.netToAgent?.amount) * trip?.passenger?.length)
        }
        if (tickets?.isFlightRoundTrip) {
            roundTripPrice = 
                (Number(selectedDepartureFlight?.journeys[0]?.fareInfo?.netToAgent?.adultFare?.amount) * trip?.passenger?.length) +
                (Number(selectedDepartureFlightBaggage?.netToAgent?.amount) * trip?.passenger?.length) +
                (Number(selectedReturnFlight?.journeys[0]?.fareInfo?.netToAgent?.adultFare?.amount) * trip?.passenger?.length) +
                (Number(selectedReturnFlightBaggage?.netToAgent?.amount) * trip?.passenger?.length) 
        } 
        if (tickets?.isBookHotel) {
            hotelPrice = Number(selectedHotel?.room?.rates?.chargeableRate?.chargeableRateInfo?.netPrice) * trip?.jumlah_kamar * trip?.hotel_durasi
        }

        return roundTripPrice + notRountripPrice + hotelPrice
    }

    async function handleSubmit() {
        let roundTripData = {
            departure: [],
            return: [],
        }
        let notRoundTripData = []
        let hotelData = []

        if (tickets?.isFlightNotRoundTrip) {
            trip?.passenger?.map((passenger) => {
                let singleNotRoundTripData = {}

                singleNotRoundTripData.pessenger_name = passenger?.passenger_name?.name
                singleNotRoundTripData.rc_code = '-'
                singleNotRoundTripData.title = `${selectedFlight?.departureAirport} - ${selectedFlight?.arrivalAirport} (${selectedFlight?.numOfTransits === '0' ? 'direct' : 'transit'})`
                singleNotRoundTripData.details = selectedFlight?.airlineDetail[0]?.airlineName
                singleNotRoundTripData.date_time = `${selectedFlight?.journeys[0]?.departureDetail?.departureDate} (${selectedFlight?.journeys[0]?.departureDetail?.departureTime} - ${selectedFlight?.journeys[0]?.arrivalDetail?.arrivalTime})`
                singleNotRoundTripData.status = 'On Booking'
                singleNotRoundTripData.info = 'On Booking'
                singleNotRoundTripData.additional_info =   selectedFlight?.journeys[0]?.refundableStatus === 'REFUNDABLE'
                                                            ?   'Refundable'
                                                            :   'Non-Refundable'
                singleNotRoundTripData.baggage_quantity = `${selectedFlightBaggage?.baggageWeight} ${selectedFlightBaggage?.baggageType}`
                singleNotRoundTripData.baggage_price = Number(selectedFlightBaggage?.netToAgent?.amount)
                singleNotRoundTripData.price = Number(selectedFlight?.journeys[0]?.fareInfo?.netToAgent?.adultFare?.amount)
                singleNotRoundTripData.subtotal =  selectedFlightBaggage
                                                    ?   Number(selectedFlight?.journeys[0]?.fareInfo?.netToAgent?.adultFare?.amount) + (Number(selectedFlightBaggage?.netToAgent?.amount))
                                                    :   Number(selectedFlight?.journeys[0]?.fareInfo?.netToAgent?.adultFare?.amount)
                
                notRoundTripData.push(singleNotRoundTripData)
            })
        }
        if (tickets?.isFlightRoundTrip) {
            trip?.passenger?.map((passenger) => {
                let singleDepartureData = {}
                let singleReturnData = {}

                singleDepartureData.pessenger_name = passenger?.passenger_name?.name
                singleDepartureData.rc_code = '-'
                singleDepartureData.title = `${selectedDepartureFlight?.departureAirport} - ${selectedDepartureFlight?.arrivalAirport} (${selectedDepartureFlight?.numOfTransits === '0' ? 'direct' : 'transit'})`
                singleDepartureData.details = selectedDepartureFlight?.airlineDetail[0]?.airlineName
                singleDepartureData.date_time = `${selectedDepartureFlight?.journeys[0]?.departureDetail?.departureDate} (${selectedDepartureFlight?.journeys[0]?.departureDetail?.departureTime} - ${selectedDepartureFlight?.journeys[0]?.arrivalDetail?.arrivalTime})`
                singleDepartureData.status = 'On Booking'
                singleDepartureData.info = 'On Booking'
                singleDepartureData.additional_info =   selectedDepartureFlight?.journeys[0]?.refundableStatus === 'REFUNDABLE'
                                                            ?   'Refundable'
                                                            :   'Non-Refundable'
                singleDepartureData.baggage_quantity = `${selectedDepartureFlightBaggage?.baggageWeight} ${selectedDepartureFlightBaggage?.baggageType}`
                singleDepartureData.baggage_price = Number(selectedDepartureFlightBaggage?.netToAgent?.amount)
                singleDepartureData.price = Number(selectedDepartureFlight?.journeys[0]?.fareInfo?.netToAgent?.adultFare?.amount)
                singleDepartureData.subtotal =  selectedDepartureFlightBaggage
                                                    ?   Number(selectedDepartureFlight?.journeys[0]?.fareInfo?.netToAgent?.adultFare?.amount) + (Number(selectedDepartureFlightBaggage?.netToAgent?.amount))
                                                    :   Number(selectedDepartureFlight?.journeys[0]?.fareInfo?.netToAgent?.adultFare?.amount)

                singleReturnData.pessenger_name = passenger?.passenger_name?.name
                singleReturnData.rc_code = '-'
                singleReturnData.title = `${selectedReturnFlight?.departureAirport} - ${selectedReturnFlight?.arrivalAirport} (${selectedReturnFlight?.numOfTransits === '0' ? 'direct' : 'transit'})`
                singleReturnData.details = selectedReturnFlight?.airlineDetail[0]?.airlineName
                singleReturnData.date_time = `${selectedReturnFlight?.journeys[0]?.departureDetail?.departureDate} (${selectedReturnFlight?.journeys[0]?.departureDetail?.departureTime} - ${selectedReturnFlight?.journeys[0]?.arrivalDetail?.arrivalTime})`
                singleReturnData.status = 'On Booking'
                singleReturnData.info = 'On Booking'
                singleReturnData.additional_info =   selectedReturnFlight?.journeys[0]?.refundableStatus === 'REFUNDABLE'
                                                            ?   'Refundable'
                                                            :   'Non-Refundable'
                singleReturnData.baggage_quantity = `${selectedReturnFlightBaggage?.baggageWeight} ${selectedReturnFlightBaggage?.baggageType}`
                singleReturnData.baggage_price = Number(selectedReturnFlightBaggage?.netToAgent?.amount)
                singleReturnData.price = Number(selectedReturnFlight?.journeys[0]?.fareInfo?.netToAgent?.adultFare?.amount)
                singleReturnData.subtotal =  selectedReturnFlightBaggage
                                                    ?   Number(selectedReturnFlight?.journeys[0]?.fareInfo?.netToAgent?.adultFare?.amount) + (Number(selectedReturnFlightBaggage?.netToAgent?.amount))
                                                    :   Number(selectedReturnFlight?.journeys[0]?.fareInfo?.netToAgent?.adultFare?.amount)
            
                roundTripData.departure.push(singleDepartureData)
                roundTripData.return.push(singleReturnData)    
            })
        } 
        if (tickets?.isBookHotel) {
            trip?.passenger?.map((passenger) => {
                let singleHotelData = {}

                singleHotelData.guest_name = passenger?.passenger_name?.name
                singleHotelData.rc_code = '-'
                singleHotelData.location = selectedHotel?.hotel?.propertySummary?.address?.city
                singleHotelData.hotel = selectedHotel?.hotel?.propertySummary?.name
                singleHotelData.check_in = {
                    timestamp: trip?.hotel_check_in,
                    string: `${format(new Date(trip?.hotel_check_in), 'EEEE, dd MMM yyyy')} dari 14:00`
                }
                singleHotelData.check_out = {
                    timestamp: trip?.hotel_check_out,
                    string: `${format(new Date(trip?.hotel_check_out), 'EEEE, dd MMM yyyy')} sebelum 12:00`
                }
                singleHotelData.duration = trip?.hotel_durasi
                singleHotelData.hotel_price = Number(selectedHotel?.room?.rates?.chargeableRate?.chargeableRateInfo?.netPrice)
                
                hotelData.push(singleHotelData)
            })
        }

        const payload = {
            data: {
                summaryRoundTrip: roundTripData, 
                summaryOneWay: notRoundTripData, 
                summaryHotel: hotelData,
                
                selectedOneWay: selectedFlight,
                selectedRoundTrip: {
                    departure: selectedDepartureFlight,
                    return: selectedReturnFlight,
                },
                selectedHotel,
            },
            isBookHotel: tickets?.isBookHotel,
            isOneWay: tickets?.isFlightNotRoundTrip,
            isRoundTrip: tickets?.isFlightRoundTrip,
            idTrip: searchParams.get('id'),
            total: handleCalculateTotalPrice()
        }
        return await sendSummary(payload)
    }

    const submitSummary = useMutation(handleSubmit, {
        onSuccess: () => {
            onSuccess()
            // window.location.href = process.env.REACT_APP_REDIRECT_URL
        }
    })

    return (
        <Modal isOpen={isOpen} onClose={onClose} size={'6xl'}>
            <ModalOverlay />
            <ModalContent margin='10px'>
                <ModalHeader 
                    as={Flex} 
                    alignItems={'center'} 
                    gap={'10px'}
                    borderBottomWidth={'2px'}
                    borderBottomColor={'gray'}
                    marginX={'20px'}
                    paddingX={'0px'}
                >
                    <Text>Summary</Text>
                </ModalHeader>
                <ModalCloseButton top={'15px'} />
                <ModalBody 
                    as={Flex} 
                    flexDirection={'column'} 
                    gap={'10px'}
                >
                    {
                        tickets?.isFlightNotRoundTrip
                            ?   <TableContainer>
                                    <Table variant='simple'>
                                        <Thead>
                                            <Tr>
                                                <Th>Pessenger Name</Th>
                                                <Th>Title</Th>
                                                <Th>Details</Th>
                                                <Th>Date Time</Th>
                                                <Th>Additional Info</Th>
                                                <Th>Baggage Quantity</Th>
                                                <Th>Baggage Price</Th>
                                                <Th>Price</Th>
                                                <Th>Subtotal</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {trip?.passenger?.map((passenger, index) => {
                                                return (
                                                    <Tr key={index}>
                                                        <Td>{passenger?.passenger_name?.name}</Td>
                                                        <Td>{selectedFlight?.departureAirport} - {selectedFlight?.arrivalAirport} ({selectedFlight?.numOfTransits === '0' ? 'direct' : 'transit'})</Td>
                                                        <Td>{selectedFlight?.airlineDetail[0]?.airlineName}</Td>
                                                        <Td>{selectedFlight?.journeys[0]?.departureDetail?.departureDate} ({selectedFlight?.journeys[0]?.departureDetail?.departureTime} - {selectedFlight?.journeys[0]?.arrivalDetail?.arrivalTime})</Td>
                                                        <Td>
                                                            {
                                                                selectedFlight?.journeys[0]?.refundableStatus === 'REFUNDABLE'
                                                                ?   'Refundable'
                                                                :   'Non-Refundable'
                                                            }
                                                        </Td>
                                                        <Td>{selectedFlightBaggage?.baggageWeight} {selectedFlightBaggage?.baggageType}</Td>
                                                        <Td>{selectedFlightBaggage?.netToAgent?.currency}. {selectedFlightBaggage?.netToAgent?.amount}</Td>
                                                        <Td>{selectedFlight?.journeys[0]?.fareInfo?.netToAgent?.adultFare?.currency}. {(Number(selectedFlight?.journeys[0]?.fareInfo?.netToAgent?.adultFare?.amount)).toLocaleString()}</Td>
                                                        <Td>
                                                            {selectedFlight?.journeys[0]?.fareInfo?.netToAgent?.adultFare?.currency}. 
                                                            {
                                                                selectedFlightBaggage
                                                                    ?   ` ${((Number(selectedFlight?.journeys[0]?.fareInfo?.netToAgent?.adultFare?.amount)) + (Number(selectedFlightBaggage?.netToAgent?.amount))).toLocaleString()}`
                                                                    :   ` ${(Number(selectedFlight?.journeys[0]?.fareInfo?.netToAgent?.adultFare?.amount)).toLocaleString()}`
                                                            }
                                                        </Td>
                                                    </Tr>
                                                )
                                            })}
                                        </Tbody>
                                    </Table>
                                </TableContainer>
                            :   null
                    }

                    {
                        tickets?.isFlightRoundTrip
                            ?   <TableContainer>
                                    <Table variant='simple'>
                                        <Thead>
                                            <Tr>
                                                <Th>Pessenger Name</Th>
                                                <Th>Title</Th>
                                                <Th>Details</Th>
                                                <Th>Date Time</Th>
                                                <Th>Additional Info</Th>
                                                <Th>Baggage Quantity</Th>
                                                <Th>Baggage Price</Th>
                                                <Th>Price</Th>
                                                <Th>Subtotal</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {trip?.passenger?.map((passenger, index) => {
                                                return (
                                                    <>
                                                        <Tr>
                                                            <Td>{passenger?.passenger_name?.name}</Td>
                                                            <Td>{selectedDepartureFlight?.departureAirport} - {selectedDepartureFlight?.arrivalAirport} ({selectedDepartureFlight?.numOfTransits === '0' ? 'direct' : 'transit'})</Td>
                                                            <Td>{selectedDepartureFlight?.airlineDetail[0]?.airlineName}</Td>
                                                            <Td>{selectedDepartureFlight?.journeys[0]?.departureDetail?.departureDate} ({selectedDepartureFlight?.journeys[0]?.departureDetail?.departureTime} - {selectedDepartureFlight?.journeys[0]?.arrivalDetail?.arrivalTime})</Td>
                                                            <Td>
                                                                {
                                                                    selectedDepartureFlight?.journeys[0]?.refundableStatus === 'REFUNDABLE'
                                                                    ?   'Refundable'
                                                                    :   'Non-Refundable'
                                                                }
                                                            </Td>
                                                            <Td>{selectedDepartureFlightBaggage?.baggageWeight} {selectedDepartureFlightBaggage?.baggageType}</Td>
                                                            <Td>{selectedDepartureFlightBaggage?.netToAgent?.currency}. {selectedDepartureFlightBaggage?.netToAgent?.amount}</Td>
                                                            <Td>{selectedDepartureFlight?.journeys[0]?.fareInfo?.netToAgent?.adultFare?.currency}. {(Number(selectedDepartureFlight?.journeys[0]?.fareInfo?.netToAgent?.adultFare?.amount)).toLocaleString()}</Td>
                                                            <Td>
                                                                {selectedDepartureFlight?.journeys[0]?.fareInfo?.netToAgent?.adultFare?.currency}. 
                                                                {
                                                                    selectedDepartureFlightBaggage
                                                                        ?   ` ${((Number(selectedDepartureFlight?.journeys[0]?.fareInfo?.netToAgent?.adultFare?.amount)) + (Number(selectedDepartureFlightBaggage?.netToAgent?.amount))).toLocaleString()}`
                                                                        :   ` ${(Number(selectedDepartureFlight?.journeys[0]?.fareInfo?.netToAgent?.adultFare?.amount)).toLocaleString()}`
                                                                }
                                                            </Td>
                                                        </Tr>
                                                        <Tr>
                                                            <Td>{passenger?.passenger_name?.name}</Td>
                                                            <Td>{selectedReturnFlight?.departureAirport} - {selectedReturnFlight?.arrivalAirport} ({selectedReturnFlight?.numOfTransits === '0' ? 'direct' : 'transit'})</Td>
                                                            <Td>{selectedReturnFlight?.airlineDetail[0]?.airlineName}</Td>
                                                            <Td>{selectedReturnFlight?.journeys[0]?.departureDetail?.departureDate} ({selectedReturnFlight?.journeys[0]?.departureDetail?.departureTime} - {selectedReturnFlight?.journeys[0]?.arrivalDetail?.arrivalTime})</Td>
                                                            <Td>
                                                                {
                                                                    selectedReturnFlight?.journeys[0]?.refundableStatus === 'REFUNDABLE'
                                                                    ?   'Refundable'
                                                                    :   'Non-Refundable'
                                                                }
                                                            </Td>
                                                            <Td>{selectedReturnFlightBaggage?.baggageWeight} {selectedReturnFlightBaggage?.baggageType}</Td>
                                                            <Td>{selectedReturnFlightBaggage?.netToAgent?.currency}. {selectedReturnFlightBaggage?.netToAgent?.amount}</Td>
                                                            <Td>{selectedReturnFlight?.journeys[0]?.fareInfo?.netToAgent?.adultFare?.currency}. {(Number(selectedReturnFlight?.journeys[0]?.fareInfo?.netToAgent?.adultFare?.amount)).toLocaleString()}</Td>
                                                            <Td>
                                                                {selectedReturnFlight?.journeys[0]?.fareInfo?.netToAgent?.adultFare?.currency}. 
                                                                {
                                                                    selectedReturnFlightBaggage
                                                                        ?   ` ${((Number(selectedReturnFlight?.journeys[0]?.fareInfo?.netToAgent?.adultFare?.amount)) + (Number(selectedReturnFlightBaggage?.netToAgent?.amount))).toLocaleString()}`
                                                                        :   ` ${(Number(selectedReturnFlight?.journeys[0]?.fareInfo?.netToAgent?.adultFare?.amount)).toLocaleString()}`
                                                                }
                                                            </Td>
                                                        </Tr>
                                                    </>
                                                )
                                            })}
                                        </Tbody>
                                    </Table>
                                </TableContainer>
                            :   null
                    }

                    {
                        tickets?.isBookHotel
                            ?   <TableContainer>
                                    <Table variant='simple'>
                                        <Thead>
                                            <Tr>
                                                <Th>Guest Name</Th>
                                                <Th>Location</Th>
                                                <Th>Hotel</Th>
                                                <Th>Room</Th>
                                                <Th>Check In</Th>
                                                <Th>Check Out</Th>
                                                <Th>Duration</Th>
                                                <Th>Total Room</Th>
                                                <Th>Base Price</Th>
                                                <Th>Total Price</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            <Tr>
                                                <Td>{trip?.guest[0]?.guest_name?.name}</Td>
                                                <Td>{selectedHotel?.hotel?.propertySummary?.address?.city}</Td>
                                                <Td>{selectedHotel?.hotel?.propertySummary?.name}</Td>
                                                <Td>{selectedHotel?.room?.name}</Td>
                                                <Td>{format(new Date(trip?.hotel_check_in), 'EEEE, dd MMM yyyy')} dari 14:00</Td>
                                                <Td>{format(new Date(trip?.hotel_check_out), 'EEEE, dd MMM yyyy')} sebelum 12:00</Td>
                                                <Td>{trip?.hotel_durasi} Night</Td>
                                                <Td>{trip?.jumlah_kamar} Room</Td>
                                                <Td>IDR. {Number(selectedHotel?.room?.rates?.chargeableRate?.chargeableRateInfo?.netPrice).toLocaleString()}</Td>
                                                <Td>IDR. {Number(Number(selectedHotel?.room?.rates?.chargeableRate?.chargeableRateInfo?.netPrice) * Number(trip?.hotel_durasi) * Number(trip?.jumlah_kamar)).toLocaleString()}</Td>
                                            </Tr>
                                        </Tbody>
                                    </Table>
                                </TableContainer>
                            :   null
                    }

                    <Text marginTop={'24px'} textAlign={'right'}>
                        <Box 
                            as={'span'} 
                            fontWeight={'semibold'} 
                            marginRight={'10px'} 
                        >
                            Total Price: IDR. {handleCalculateTotalPrice().toLocaleString()}
                        </Box>
                    </Text>
                </ModalBody>
                <ModalFooter>
                    <Button variant='ghost' onClick={onClose}>Cancel</Button>
                    <Button 
                        colorScheme='blue' 
                        onClick={() => {
                            submitSummary.mutate()
                        }}
                        isLoading={submitSummary.isLoading}
                    >
                        Submit
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default ModalSummary