import React from 'react'
import { MdStar } from 'react-icons/md'
import { 
    Button, 
    Flex, 
    Icon, 
    Image, 
    Text, 
    useMediaQuery, 
} from '@chakra-ui/react'

const HotelItem = ({hotel, onChoose}) => {
    const [isLargerThan500] = useMediaQuery('(min-width: 500px)')

    return (
        <Flex 
            flexDirection={isLargerThan500 ? 'row' : 'column'} 
            alignItems='center'
            justifyContent='space-between'
            boxShadow={'0px 0px 10px 1px #e8e8e8'}
            rounded={'md'} 
            padding={'20px'}
        >
            <Flex 
                width='100%'
                alignItems='center' 
                gap='20px' 
                flexDirection={isLargerThan500 ? 'row' : 'column'}
            >
                <Image 
                    src={hotel?.propertyImages[0]?.entries[0]?.url} 
                    height='125px' 
                    width={isLargerThan500 ? '200px' : '100%'} 
                    objectFit='cover' 
                    rounded='md' 
                />
                <Flex flexDirection='column' width={isLargerThan500 ? 'unset' : '100%'} >
                    <Text fontWeight='semibold' fontSize='lg'>{hotel?.propertySummary?.name}</Text>
                    {Number(hotel?.propertySummary?.starRating) === 1 &&
                        <Flex>
                            <Icon as={MdStar} color='orange.400' />
                        </Flex>
                    }
                    {Number(hotel?.propertySummary?.starRating) === 2 &&
                        <Flex>
                            <Icon as={MdStar} color='orange.400' />
                            <Icon as={MdStar} color='orange.400' />
                        </Flex>
                    }
                    {Number(hotel?.propertySummary?.starRating) === 3 &&
                        <Flex>
                            <Icon as={MdStar} color='orange.400' />
                            <Icon as={MdStar} color='orange.400' />
                            <Icon as={MdStar} color='orange.400' />
                        </Flex>
                    }
                    {Number(hotel?.propertySummary?.starRating) === 4 &&
                        <Flex>
                            <Icon as={MdStar} color='orange.400' />
                            <Icon as={MdStar} color='orange.400' />
                            <Icon as={MdStar} color='orange.400' />
                            <Icon as={MdStar} color='orange.400' />
                        </Flex>
                    }
                    {Number(hotel?.propertySummary?.starRating) === 5 &&
                        <Flex>
                            <Icon as={MdStar} color='orange.400' />
                            <Icon as={MdStar} color='orange.400' />
                            <Icon as={MdStar} color='orange.400' />
                            <Icon as={MdStar} color='orange.400' />
                            <Icon as={MdStar} color='orange.400' />
                        </Flex>
                    }
                    <Text fontWeight='thin'>Start From</Text>
                    <Text fontWeight={'semibold'} color={'orange.400'}>IDR. {(Number(hotel?.cheapestRoom?.convertedChargeableRate?.averageRate)).toLocaleString()}</Text>
                    <Text fontWeight='thin'>/room/night</Text>
                </Flex>
            </Flex>
            <Button 
                marginTop={isLargerThan500 ? 'unset' : '1rem'}
                backgroundColor={'orange.400'} 
                color={'white'} 
                onClick={onChoose}
                width={isLargerThan500 ? 'unset' : 'full'}
            >
                See Detail
            </Button>
        </Flex>
    )
}

export default HotelItem