import React from 'react'
import { Box, Flex, Icon, Image, Text } from '@chakra-ui/react'
import { MdHotel, MdStar } from 'react-icons/md'
import { IoMdCloseCircle } from 'react-icons/io'

const SelectedHotel = ({ hotel, onDelete }) => {
    console.log(hotel)
    return (
        <Flex 
            alignItems={'center'} 
            gap={'20px'} 
            padding={'20px'}
            rounded={'md'}
            boxShadow={'0px 0px 10px 1px #e8e8e8'}
            width={'max-content'}
            position={'relative'}
        >
            <MdHotel fontSize={'50px'} />
            <Flex flexDirection={'column'}>
                <Flex 
                    alignItems={'center'} 
                    gap={'10px'} 
                    marginBottom={'10px'}
                >
                    <Image
                        width={'100px'}
                        src={hotel?.images[0]?.entries[0]?.url}
                        objectFit={'cover'}
                        rounded='md'
                    />
                </Flex>
                <Text fontWeight={'semibold'}>{hotel?.hotel?.propertySummary?.name}</Text>
                {Number(hotel?.hotel?.propertySummary?.starRating) === 1 &&
                    <Flex>
                        <Icon as={MdStar} color='orange.400' />
                    </Flex>
                }
                {Number(hotel?.hotel?.propertySummary?.starRating) === 2 &&
                    <Flex>
                        <Icon as={MdStar} color='orange.400' />
                        <Icon as={MdStar} color='orange.400' />
                    </Flex>
                }
                {Number(hotel?.hotel?.propertySummary?.starRating) === 3 &&
                    <Flex>
                        <Icon as={MdStar} color='orange.400' />
                        <Icon as={MdStar} color='orange.400' />
                        <Icon as={MdStar} color='orange.400' />
                    </Flex>
                }
                {Number(hotel?.hotel?.propertySummary?.starRating) === 4 &&
                    <Flex>
                        <Icon as={MdStar} color='orange.400' />
                        <Icon as={MdStar} color='orange.400' />
                        <Icon as={MdStar} color='orange.400' />
                        <Icon as={MdStar} color='orange.400' />
                    </Flex>
                }
                {Number(hotel?.hotel?.propertySummary?.starRating) === 5 &&
                    <Flex>
                        <Icon as={MdStar} color='orange.400' />
                        <Icon as={MdStar} color='orange.400' />
                        <Icon as={MdStar} color='orange.400' />
                        <Icon as={MdStar} color='orange.400' />
                        <Icon as={MdStar} color='orange.400' />
                    </Flex>
                }
                <Text fontWeight={'medium'}>
                    {(Number(hotel?.room?.rates?.chargeableRate?.chargeableRateInfo?.netPrice)).toLocaleString()}
                    <Box as={'span'} fontWeight={'thin'}> /room/night</Box>
                </Text>
            </Flex>
            <Icon 
                as={IoMdCloseCircle} 
                color={'red'} 
                fontSize={'24px'} 
                position={'absolute'}
                right={'-10px'}
                top={'-10px'}
                cursor={'pointer'}
                onClick={onDelete}
            />
        </Flex>
    )
}

export default SelectedHotel