import React from 'react'
import { ImSpoonKnife } from 'react-icons/im'
import { IoIosWifi } from 'react-icons/io'
import { BiBed, BiGroup } from 'react-icons/bi'
import { 
    Button, 
    Flex, 
    Icon, 
    Image, 
    Text, 
    useMediaQuery, 
} from '@chakra-ui/react'

const RoomItem = ({ room, onChoose, onClickImage }) => {
    const [isLargerThan500] = useMediaQuery('(min-width: 500px)')

    return (
        <Flex 
            flexDirection={isLargerThan500 ? 'row' : 'column'} 
            alignItems='center'
            justifyContent='space-between'
            boxShadow={'0px 0px 10px 1px #e8e8e8'}
            rounded={'md'} 
            padding={'20px'}
            width='100%'
        >
            <Flex 
                alignItems='center' 
                gap='20px'
                flexDirection={isLargerThan500 ? 'row' : 'column'}
            >
                {
                    room?.images?.length > 0 &&
                    <Image 
                        cursor='pointer' 
                        onClick={onClickImage} 
                        src={room?.images[0]?.entries[0]?.url} 
                        width={isLargerThan500 ? '150px' : '100%'} 
                        objectFit='cover' 
                        rounded='md' 
                    />
                }
                <Flex flexDirection='column'>
                    <Text fontWeight='semibold' fontSize='lg'>{room?.name}</Text>
                    <Text fontWeight={'semibold'} color={'orange.400'}>IDR. {(Number(room?.rates?.chargeableRate?.chargeableRateInfo?.netPrice)).toLocaleString()}</Text>
                    <Text fontWeight='thin'>/room/night</Text>
                    <Flex marginTop='10px' gap='10px' fontSize='14px'>
                        <Flex gap='5px' alignItems='center' color='gray.600'>
                            <Icon fontSize='18px' as={BiBed} />
                            <Text>{room?.bedroomSummary}</Text>
                        </Flex>
                        <Flex gap='5px' alignItems='center' color='gray.600'>
                            <Icon fontSize='18px' as={BiGroup} />
                            <Text>{room?.maxOccupancy} tamu</Text>
                        </Flex>
                    </Flex>
                    <Flex marginTop='10px' gap='10px' fontSize='14px'>
                        {room?.breakfastIncluded
                            ?   <Flex gap='5px' alignItems='center' color='green.300'>
                                    <Icon fontSize='18px' as={ImSpoonKnife} />
                                    <Text>Sarapan Gratis</Text>
                                </Flex>
                            :   <Flex gap='5px' alignItems='center' color='gray.400'>
                                    <Icon fontSize='18px' as={ImSpoonKnife} />
                                    <Text>Tidak Dapat Sarapan</Text>
                                </Flex>
                        }
                        {room?.wifiIncluded
                            ?   <Flex gap='5px' alignItems='center' color='green.300'>
                                    <Icon fontSize='18px' as={IoIosWifi} />
                                    <Text>Wifi Gratis</Text>
                                </Flex>
                            :   <Flex gap='5px' alignItems='center' color='gray.400'>
                                    <Icon fontSize='18px' as={IoIosWifi} />
                                    <Text>Tidak Ada Wifi</Text>
                                </Flex>
                        }
                    </Flex>
                    <Flex marginTop='10px' direction='column' fontSize='14px'>
                        {room?.amenities?.map((amenitiy, index) => <Text key={index} color='green.300'>{amenitiy?.localizedName}</Text>)}
                    </Flex>
                </Flex>
            </Flex>
            <Button 
                backgroundColor={'orange.400'} 
                color={'white'} 
                onClick={onChoose}
                marginTop={isLargerThan500 ? 'unset' : '1rem'}
                width={isLargerThan500 ? 'unset' : 'full'}
            >
                Choose Room
            </Button>
        </Flex>
    )
}

export default RoomItem