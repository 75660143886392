import React from 'react'
import { MdOutlineFlight } from 'react-icons/md'
import { TbRepeat, TbRepeatOff } from 'react-icons/tb'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Flex,
    Text,
    Image,
    Grid,
    GridItem,
    useMediaQuery,
} from '@chakra-ui/react'

function ModalBaggage({ 
    type,
    isOpen,
    onClose,
    onContinue,
    tickets,
    selectedFlight,
    selectedFlightBaggage,
    setSelectedFlightBaggage,
    selectedDepartureFlight,
    selectedDepartureFlightBaggage,
    setSelectedDepartureFlightBaggage,
    selectedReturnFlight,
    selectedReturnFlightBaggage,
    setSelectedReturnFlightBaggage,
}) {
    const [isLargerThan500] = useMediaQuery('(min-width: 500px)')

    const handleNumberToHoursAndMinutes = (number) => {
        const hours = Math.floor(number / 60)  
        const minutes = number % 60
        return hours + "h" + " " + minutes + "m"  
    } 

    return (
        <Modal isOpen={isOpen} onClose={onClose} size={'6xl'}>
            <ModalOverlay />
            <ModalContent margin='10px'>
            <ModalHeader 
                as={Flex} 
                direction={isLargerThan500 ? 'row' : 'column'}
                alignItems={'center'} 
                gap={'10px'}
                borderBottomWidth={'2px'}
                borderBottomColor={'gray'}
                marginX={'20px'}
                paddingX={'0px'}
            >
                <Flex
                    alignItems={'center'} 
                    gap={'10px'}
                >
                    <Text>Select Baggage</Text>
                    <Text>|</Text> 
                    <MdOutlineFlight fontSize={'24px'} color={'#307dc8'} />
                </Flex>
                
                <Flex
                    direction={isLargerThan500 ? 'row' : 'column'}
                    alignItems={'center'} 
                    gap={'10px'}
                >
                    {
                        type === 'round-trip'
                            ?   <Text>{selectedDepartureFlight?.departureAirportDetail?.city} ({selectedDepartureFlight?.departureAirport})</Text>
                            :   <Text>{selectedFlight?.departureAirportDetail?.city} ({selectedFlight?.departureAirport})</Text>
                    }
                    {
                        type === 'round-trip'
                            ?   <TbRepeat fontSize={'24px'} color={'gray'} />
                            :   <TbRepeatOff fontSize={'24px'} color={'gray'} />
                    }
                    {
                        type === 'round-trip'
                            ?   <Text>{selectedDepartureFlight?.arrivalAirportDetail?.city} ({selectedDepartureFlight?.arrivalAirport})</Text>
                            :   <Text>{selectedFlight?.arrivalAirportDetail?.city} ({selectedFlight?.arrivalAirport})</Text>
                    }
                </Flex>
            </ModalHeader>
            <ModalCloseButton top={'15px'} />
                <ModalBody 
                    as={Flex} 
                    flexDirection={'column'} 
                    gap={'10px'}
                >
                    <Flex gap={'10px'} direction={isLargerThan500 ? 'row' : 'column'}>
                        <Flex 
                            flexDirection={'column'} 
                            width={isLargerThan500 ? '150%' : '100%'} 
                            gap={'10px'}
                        >
                            {
                                type === 'round-trip'
                                    ?   <>
                                            <Flex 
                                                flexDirection={'column'} 
                                                padding={'20px'} 
                                                rounded={'base'}
                                                boxShadow={'0px 0px 10px 1px #e8e8e8'}
                                            >
                                                <Text fontWeight={'semibold'} marginBottom={'10px'} fontSize={'lg'}>
                                                    {selectedDepartureFlight?.journeys[0]?.departureDetail?.departureDate}
                                                </Text>
                                                <Flex flexDirection={'column'} gap={'10px'}> 
                                                    <Flex alignItems={'center'} gap={'10px'}>
                                                        <Image
                                                            height={'40px'}
                                                            src={selectedDepartureFlight?.airlineDetail[0]?.logoUrl}
                                                            objectFit={'cover'}
                                                        />
                                                        <Flex flexDirection={'column'}>
                                                            <Text fontWeight={'semibold'}>{selectedDepartureFlight?.airlineDetail[0]?.airlineName} ({selectedDepartureFlight?.journeys[0]?.segments[0]?.flightCode})</Text>
                                                            {/* <Text fontWeight={'thin'}>{oosData?.seatClass}</Text> */}
                                                        </Flex>
                                                    </Flex>
                                                    <Flex 
                                                        direction={isLargerThan500 ? 'row' : 'column'}
                                                        gap={'20px'} 
                                                        justifyContent={'space-between'} 
                                                        marginTop={'10px'}
                                                    >
                                                        <Flex flexDirection={'column'}>
                                                            <Text fontWeight={'semibold'}>{selectedDepartureFlight?.journeys[0]?.departureDetail?.departureTime}</Text>
                                                            <Text fontWeight={'thin'}>{selectedDepartureFlight?.departureAirportDetail?.city} ({selectedDepartureFlight?.departureAirport})</Text>
                                                        </Flex>
                                                        <Flex flexDirection={'column'}>
                                                        <Text fontWeight={'semibold'}>{selectedDepartureFlight?.journeys[0]?.arrivalDetail?.arrivalTime}</Text>
                                                            <Text fontWeight={'thin'}>{selectedDepartureFlight?.arrivalAirportDetail?.city} ({selectedDepartureFlight?.arrivalAirport})</Text>
                                                        </Flex>
                                                        <Flex flexDirection={'column'}>
                                                            <Text fontWeight={'semibold'}>{handleNumberToHoursAndMinutes(selectedDepartureFlight?.journeys[0].journeyDuration)}</Text>
                                                            <Text fontWeight={'thin'}>{selectedDepartureFlight?.numOfTransits === '0' ? 'direct' : 'transit'}</Text>
                                                        </Flex>
                                                    </Flex>
                                                </Flex>
                                                <Grid 
                                                    templateColumns={isLargerThan500 ? 'repeat(4, 1fr)' : 'repeat(2, 1fr)'} 
                                                    gap={'10px'} 
                                                    marginTop={'20px'}
                                                >
                                                    {selectedDepartureFlight?.journeys[0]?.segments[0]?.addOns?.baggageOptions?.map((b, i) => {
                                                        return (
                                                            <GridItem
                                                                key={i}
                                                                boxShadow={'0px 0px 10px 1px #e8e8e8'}
                                                                padding={'20px'}
                                                                rounded={'base'}
                                                                textAlign={'center'}
                                                                onClick={() => setSelectedDepartureFlightBaggage(b)}
                                                                cursor={'pointer'}
                                                                backgroundColor={selectedDepartureFlightBaggage?.id === b?.id ? 'blue.500' : 'unset'}
                                                                color={selectedDepartureFlightBaggage?.id === b?.id ? 'white' : 'unset'}
                                                            >
                                                                <Text fontWeight={'semibold'}>{b?.baggageWeight} {b?.baggageType}</Text>
                                                                <Text>{b?.netToAgent?.currency} {b?.netToAgent?.amount}</Text>
                                                            </GridItem>
                                                        )
                                                    })}
                                                </Grid>
                                            </Flex>
                                            <Flex 
                                                flexDirection={'column'} 
                                                padding={'20px'} 
                                                rounded={'base'}
                                                boxShadow={'0px 0px 10px 1px #e8e8e8'}
                                            >
                                                <Text fontWeight={'semibold'} marginBottom={'10px'} fontSize={'lg'}>
                                                    {selectedReturnFlight?.journeys[0]?.departureDetail?.departureDate}
                                                </Text>
                                                <Flex flexDirection={'column'} gap={'10px'}> 
                                                    <Flex alignItems={'center'} gap={'10px'}>
                                                        <Image
                                                            height={'40px'}
                                                            src={selectedReturnFlight?.airlineDetail[0]?.logoUrl}
                                                            objectFit={'cover'}
                                                        />
                                                        <Flex flexDirection={'column'}>
                                                            <Text fontWeight={'semibold'}>{selectedReturnFlight?.airlineDetail[0]?.airlineName} ({selectedReturnFlight?.journeys[0]?.segments[0]?.flightCode})</Text>
                                                            {/* <Text fontWeight={'thin'}>{oosData?.seatClass}</Text> */}
                                                        </Flex>
                                                    </Flex>
                                                    <Flex 
                                                        gap={'20px'} 
                                                        justifyContent={'space-between'} 
                                                        marginTop={'10px'}
                                                        direction={isLargerThan500 ? 'row' : 'column'}
                                                    >
                                                        <Flex flexDirection={'column'}>
                                                            <Text fontWeight={'semibold'}>{selectedReturnFlight?.journeys[0]?.departureDetail?.departureTime}</Text>
                                                            <Text fontWeight={'thin'}>{selectedReturnFlight?.departureAirportDetail?.city} ({selectedReturnFlight?.departureAirport})</Text>
                                                        </Flex>
                                                        <Flex flexDirection={'column'}>
                                                        <Text fontWeight={'semibold'}>{selectedReturnFlight?.journeys[0]?.arrivalDetail?.arrivalTime}</Text>
                                                            <Text fontWeight={'thin'}>{selectedReturnFlight?.arrivalAirportDetail?.city} ({selectedReturnFlight?.arrivalAirport})</Text>
                                                        </Flex>
                                                        <Flex flexDirection={'column'}>
                                                            <Text fontWeight={'semibold'}>{handleNumberToHoursAndMinutes(selectedReturnFlight?.journeys[0].journeyDuration)}</Text>
                                                            <Text fontWeight={'thin'}>{selectedReturnFlight?.numOfTransits === '0' ? 'direct' : 'transit'}</Text>
                                                        </Flex>
                                                    </Flex>
                                                </Flex>
                                                <Grid 
                                                    templateColumns={isLargerThan500 ? 'repeat(4, 1fr)' : 'repeat(2, 1fr)'} 
                                                    gap={'10px'} 
                                                    marginTop={'20px'}
                                                >
                                                    {selectedReturnFlight?.journeys[0]?.segments[0]?.addOns?.baggageOptions?.map((b, i) => {
                                                        return (
                                                            <GridItem
                                                                key={i}
                                                                boxShadow={'0px 0px 10px 1px #e8e8e8'}
                                                                padding={'20px'}
                                                                rounded={'base'}
                                                                textAlign={'center'}
                                                                onClick={() => setSelectedReturnFlightBaggage(b)}
                                                                cursor={'pointer'}
                                                                backgroundColor={selectedReturnFlightBaggage?.id === b?.id ? 'blue.500' : 'unset'}
                                                                color={selectedReturnFlightBaggage?.id === b?.id ? 'white' : 'unset'}
                                                            >
                                                                <Text fontWeight={'semibold'}>{b?.baggageWeight} {b?.baggageType}</Text>
                                                                <Text>{b?.netToAgent?.currency} {b?.netToAgent?.amount}</Text>
                                                            </GridItem>
                                                        )
                                                    })}
                                                </Grid>
                                            </Flex>
                                        </>
                                    :   <Flex 
                                            flexDirection={'column'} 
                                            padding={'20px'} 
                                            rounded={'base'}
                                            boxShadow={'0px 0px 10px 1px #e8e8e8'}
                                        >
                                            <Text fontWeight={'semibold'} marginBottom={'10px'} fontSize={'lg'}>
                                                {selectedFlight?.journeys[0]?.departureDetail?.departureDate}
                                            </Text>
                                            <Flex flexDirection={'column'} gap={'10px'}> 
                                                <Flex alignItems={'center'} gap={'10px'}>
                                                    <Image
                                                        height={'40px'}
                                                        src={selectedFlight?.airlineDetail[0]?.logoUrl}
                                                        objectFit={'cover'}
                                                    />
                                                    <Flex flexDirection={'column'}>
                                                        <Text fontWeight={'semibold'}>{selectedFlight?.airlineDetail[0]?.airlineName} ({selectedFlight?.journeys[0]?.segments[0]?.flightCode})</Text>
                                                        {/* <Text fontWeight={'thin'}>{oosData?.seatClass}</Text> */}
                                                    </Flex>
                                                </Flex>
                                                <Flex 
                                                    gap={'20px'} 
                                                    justifyContent={'space-between'} 
                                                    marginTop={'10px'}
                                                    direction={isLargerThan500 ? 'row' : 'column'}
                                                >
                                                    <Flex flexDirection={'column'}>
                                                        <Text fontWeight={'semibold'}>{selectedFlight?.journeys[0]?.departureDetail?.departureTime}</Text>
                                                        <Text fontWeight={'thin'}>{selectedFlight?.departureAirportDetail?.city} ({selectedFlight?.departureAirport})</Text>
                                                    </Flex>
                                                    <Flex flexDirection={'column'}>
                                                    <Text fontWeight={'semibold'}>{selectedFlight?.journeys[0]?.arrivalDetail?.arrivalTime}</Text>
                                                        <Text fontWeight={'thin'}>{selectedFlight?.arrivalAirportDetail?.city} ({selectedFlight?.arrivalAirport})</Text>
                                                    </Flex>
                                                    <Flex flexDirection={'column'}>
                                                        <Text fontWeight={'semibold'}>{handleNumberToHoursAndMinutes(selectedFlight?.journeys[0].journeyDuration)}</Text>
                                                        <Text fontWeight={'thin'}>{selectedFlight?.numOfTransits === '0' ? 'direct' : 'transit'}</Text>
                                                    </Flex>
                                                </Flex>
                                            </Flex>
                                            <Grid 
                                                templateColumns={isLargerThan500 ? 'repeat(4, 1fr)' : 'repeat(2, 1fr)'} 
                                                gap={'10px'} 
                                                marginTop={'20px'}
                                            >
                                                {selectedFlight?.journeys[0]?.segments[0]?.addOns?.baggageOptions?.map((b, i) => {
                                                    return (
                                                        <GridItem
                                                            key={i}
                                                            boxShadow={'0px 0px 10px 1px #e8e8e8'}
                                                            padding={'20px'}
                                                            rounded={'base'}
                                                            textAlign={'center'}
                                                            onClick={() => setSelectedFlightBaggage(b)}
                                                            cursor={'pointer'}
                                                            backgroundColor={selectedFlightBaggage?.id === b?.id ? 'blue.500' : 'unset'}
                                                            color={selectedFlightBaggage?.id === b?.id ? 'white' : 'unset'}
                                                        >
                                                            <Text fontWeight={'semibold'}>{b?.baggageWeight} {b?.baggageType}</Text>
                                                            <Text>{b?.netToAgent?.currency} {b?.netToAgent?.amount}</Text>
                                                        </GridItem>
                                                    )
                                                })}
                                            </Grid>
                                        </Flex>
                            }
                        </Flex>
                        <Flex flexDirection={'column'} width={'100%'}>
                            <Text fontWeight={'semibold'}>Additional Information</Text>
                            {
                                type === 'round-trip'
                                    ?   <>
                                            <Flex flexDirection={'column'} marginTop={'20px'}>
                                                <Text>{selectedDepartureFlight?.departureAirport} - {selectedDepartureFlight?.arrivalAirport}</Text>
                                                <Text 
                                                    color={
                                                        selectedDepartureFlight?.journeys[0]?.refundableStatus === 'REFUNDABLE'
                                                            ?   'green.300'
                                                            :   'red.300'
                                                    }
                                                >{selectedDepartureFlight?.journeys[0]?.refundableStatus === 'REFUNDABLE' ? 'Refundable' : 'Not Refundable'}</Text>
                                            </Flex>
                                            <Flex flexDirection={'column'} marginTop={'20px'}>
                                                <Text>{selectedReturnFlight?.departureAirport} - {selectedReturnFlight?.arrivalAirport}</Text>
                                                <Text 
                                                    color={
                                                        selectedReturnFlight?.journeys[0]?.refundableStatus === 'REFUNDABLE'
                                                            ?   'green.300'
                                                            :   'red.300'
                                                    }
                                                >{selectedReturnFlight?.journeys[0]?.refundableStatus === 'REFUNDABLE' ? 'Refundable' : 'Not Refundable'}</Text>
                                            </Flex>
                                        </>
                                    :   <Flex flexDirection={'column'} marginTop={'20px'}>
                                            <Text>{selectedFlight?.departureAirport} - {selectedFlight?.arrivalAirport}</Text>
                                            <Text 
                                                color={
                                                    selectedFlight?.journeys[0]?.refundableStatus === 'REFUNDABLE'
                                                        ?   'green.300'
                                                        :   'red.300'
                                                }
                                            >{selectedFlight?.journeys[0]?.refundableStatus === 'REFUNDABLE' ? 'Refundable' : 'Not Refundable'}</Text>
                                        </Flex>
                            }
                            <Text fontWeight={'semibold'} marginTop={'20px'}>Price Detail</Text>
                            <Flex justifyContent={'space-between'}>
                                <Text>
                                    {
                                        type === 'round-trip'
                                            ?   `${selectedDepartureFlight?.airlineDetail[0]?.airlineName} ${tickets?.data?.data?.trip?.passenger.length}x`
                                            :   `${selectedFlight?.airlineDetail[0]?.airlineName} ${tickets?.data?.data?.trip?.passenger.length}x`
                                    }
                                </Text>
                                <Text>
                                    {
                                        type === 'round-trip' 
                                            ?   selectedDepartureFlight?.journeys[0]?.fareInfo?.netToAgent?.adultFare?.currency
                                            :   selectedFlight?.journeys[0]?.fareInfo?.netToAgent?.adultFare?.currency
                                    }. {" "}
                                    {
                                        type === 'round-trip'
                                            ?   (Number(selectedDepartureFlight?.journeys[0]?.fareInfo?.netToAgent?.adultFare?.amount) * tickets?.data?.data?.trip?.passenger.length).toLocaleString()
                                            :   (Number(selectedFlight?.journeys[0]?.fareInfo?.netToAgent?.adultFare?.amount) * tickets?.data?.data?.trip?.passenger.length).toLocaleString()
                                    }
                                </Text>
                            </Flex>
                            {type === 'round-trip' &&
                                <Flex justifyContent={'space-between'}>
                                    <Text>
                                        {selectedReturnFlight?.airlineDetail[0]?.airlineName} {tickets?.data?.data?.trip?.passenger.length}x
                                    </Text>
                                    <Text>
                                        {selectedReturnFlight?.journeys[0]?.fareInfo?.netToAgent?.adultFare?.currency}. {" "}
                                        {(Number(selectedReturnFlight?.journeys[0]?.fareInfo?.netToAgent?.adultFare?.amount) * tickets?.data?.data?.trip?.passenger.length).toLocaleString()}
                                    </Text>
                                </Flex>
                            }
                            <Text marginTop={'20px'}>Baggage</Text>
                                {
                                    selectedDepartureFlightBaggage && selectedReturnFlightBaggage && type === 'round-trip' &&
                                    <>
                                        <Flex justifyContent={'space-between'}>
                                            <Text>{selectedDepartureFlight?.departureAirport}-{selectedDepartureFlight?.arrivalAirport} {selectedDepartureFlightBaggage?.baggageWeight} {selectedDepartureFlightBaggage?.baggageType} {tickets?.data?.data?.trip?.passenger.length}x</Text>
                                            <Text>{selectedDepartureFlightBaggage?.netToAgent?.currency} {selectedDepartureFlightBaggage?.netToAgent?.amount}</Text>
                                        </Flex>
                                        <Flex justifyContent={'space-between'}>
                                            <Text>{selectedReturnFlight?.departureAirport}-{selectedReturnFlight?.arrivalAirport} {selectedReturnFlightBaggage?.baggageWeight} {selectedReturnFlightBaggage?.baggageType} {tickets?.data?.data?.trip?.passenger.length}x</Text>
                                            <Text>{selectedReturnFlightBaggage?.netToAgent?.currency} {selectedReturnFlightBaggage?.netToAgent?.amount}</Text>
                                        </Flex>
                                    </>
                                }
                                {
                                    selectedFlightBaggage && type === 'not-round-trip' &&
                                    <Flex justifyContent={'space-between'}>
                                        <Text>{selectedFlight?.departureAirport}-{selectedFlight?.arrivalAirport} {selectedFlightBaggage?.baggageWeight} {selectedFlightBaggage?.baggageType} {tickets?.data?.data?.trip?.passenger.length}x</Text>
                                        <Text>{selectedFlightBaggage?.netToAgent?.currency} {selectedFlightBaggage?.netToAgent?.amount}</Text>
                                    </Flex>
                                }
                            <Flex justifyContent={'space-between'} marginTop={'20px'}>
                                <Text fontWeight={'semibold'}>Price you pay</Text>
                                <Text>
                                    {
                                        type === 'round-trip'
                                            ?   selectedDepartureFlight?.journeys[0]?.fareInfo?.netToAgent?.adultFare?.currency
                                            :   selectedFlight?.journeys[0]?.fareInfo?.netToAgent?.adultFare?.currency
                                    }. {" "}
                                    {
                                        type === 'round-trip'
                                            ?   ((Number(selectedDepartureFlight?.journeys[0]?.fareInfo?.netToAgent?.adultFare?.amount) * tickets?.data?.data?.trip?.passenger.length) +
                                                (Number(selectedDepartureFlightBaggage?.netToAgent?.amount) * tickets?.data?.data?.trip?.passenger.length) +
                                                (Number(selectedReturnFlight?.journeys[0]?.fareInfo?.netToAgent?.adultFare?.amount) * tickets?.data?.data?.trip?.passenger.length) +
                                                (Number(selectedReturnFlightBaggage?.netToAgent?.amount) * tickets?.data?.data?.trip?.passenger.length)).toLocaleString()
                                            :   ((Number(selectedFlight?.journeys[0]?.fareInfo?.netToAgent?.adultFare?.amount) * tickets?.data?.data?.trip?.passenger.length) +
                                                (Number(selectedFlightBaggage?.netToAgent?.amount) * tickets?.data?.data?.trip?.passenger.length)).toLocaleString()
                                    }
                                </Text>
                            </Flex>
                        </Flex>
                    </Flex>
                </ModalBody>
                <ModalFooter>
                    <Button variant='ghost' onClick={onClose}>Back to Ticket</Button>
                    <Button 
                        colorScheme='blue' 
                        ml={'20px'} 
                        onClick={() => {
                            onClose()
                            onContinue()
                        }}
                    >
                        Continue
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default ModalBaggage